<template>
  <div class="modal-backdrop">
    <div class="modal modal--large">
      
        <div class="modal-container modal-container--register">
          <div class="grid grid-2 modal--register">
            <div>
              <header>
                <h3>Aún no te encuentras registrado</h3>
                <p class="modal__description">Crea tu cuenta en Dorapp para interactuar y participar con la comunidad.</p>
              </header>
              <section class="form__actions">
                <router-link class="btn btn--primary" :to="{name: 'Login'}">Ir al registro</router-link>
                <button class="btn btn--primary btn--text" @click.prevent="$emit('close')">Seguir</button>
              </section>
            </div>
            <div class="modal__image-container text-right">
              <img class="modal__image" src="@/assets/images/illustrations/ill_community.png">
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'ModalDialog',
  props: {
    sharing: Object
  },
  data() {
    return {
    }
  }
}
</script>

<template>
  <div class="content-wrapper__layout">
    <side-nav></side-nav>

    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--31" :ref="'container'">
      <section class="main-content" v-if="profile">
        <div class="section">
          <div class="box box--no-shadow profile-notice" v-if="$store.state.user.id == profile.idUsu && $route.name == 'MyProfile'">
              <h5 class="box__title">Perfil público</h5>
              <p class="caption">Esta es una visualizacion de tu perfíl público, así es como te ven en la plataforma. Haz click en el botón actualizar perfíl para realizar algunos ajustes.</p>
          </div>
          <div class="profile-info">
              <div class="profile-info__image">
                  <div v-if="profile.imgUsu" class="user__image user__image--big">
                      <img referrerpolicy="no-referrer" :src="profile.imgUsu">
                  </div>
                  <div v-else class="user__initials user__initials--big">
                      {{profile.nomUsu?.slice(0,1)}}{{profile.apePatUsu?.slice(0,1)}}
                  </div>
              </div>
              <div class="profile-info__data-container">
                  <div class="profile-info__data">
                    <h3>{{profile.nomUsu}} {{profile.apePatUsu}} {{profile.apeMatUsu}}</h3>
                    <p>{{profile.descUsu}}</p>

                    <div class="profile-info__social">
                      <a class="profile-info__social--li" v-if="profile.linkedIn && profile.linkedIn.length > 0" :href="profile.linkedIn" target="_blank">
                        <img class="icon" src="@/assets/images/icons/ico_social_linkedin--gray.svg" alt="En la imagen el logo de LinkedIn">
                      </a>
                      <a class="profile-info__social--email" v-if="profile.publicEmail && profile.publicEmail.length > 0" :href="`mailto:${profile.publicEmail}`">
                        <img class="icon" src="@/assets/images/icons/ico_email--gray.svg" alt="En la imagen el logo de un arroba">
                      </a>
                    </div>
                  </div>
                  <div class="profile-info__extras">
                      <div class="profile-info__statistics">
                          <div class="profile-info__statistic">
                              <h4>{{this.total}}</h4>
                              <p>Publicaciones</p>
                          </div>
                          <div class="profile-info__statistic">
                              <h4>{{this.totalComments}}</h4>
                              <p>Respuestas</p>
                          </div>
                      </div>
                      <router-link class="btn btn--primary btn--outline btn--sm" v-if="$store.state.user.id == profile.idUsu" :to="{ name: 'MyProfileEdit' }">Actualizar perfil</router-link>
                  </div>
              </div>
          </div>
        </div>

        <div class="section">
          <div class="section__titles">
            <h5 class="section__title text-alt">Últimas publicaciones</h5>
            <div class="form__group form__group--sm form__group--auto-width">
              <select class="form__input" name="" id="" v-model="sortOrder" @change.prevent="sortEntries()">
                <option :value="null" selected disabled>Ordenar por</option>
                <option value="default">Últimas publicaciones</option>
                <option value="lastMonth">Publicaciones más populares en el último mes</option>
                <option value="allTime">Publicaciones más comentadas</option>
              </select>
            </div>
          </div>
          <div class="post-list">
            <Loader v-for="index in perPage" :key="index" viewBox="0 0 300 200">
              <rect x="0" y="0" rx="3" ry="3" width="250" height="30" />
              <rect x="20" y="40" rx="3" ry="3" width="260" height="15" />
              <rect x="20" y="70" rx="3" ry="3" width="260" height="15" />
              <rect x="20" y="100" rx="3" ry="3" width="170" height="15" />
            </Loader>
            <template v-if="!loading">
              <div class="listed-post" v-for="post in posts" :key="post.id">
                <div class="post__header">
                  <div class="post__author">
                    <router-link :to="{name: 'Profile', params: {id: post.author.id}}">
                      <div class="user__image" v-if="post.author?.profilePicture">
                        <img referrerpolicy="no-referrer" :src="post.author.profilePicture">
                      </div>
                      <div class="user__image" v-else>
                        <img src="@/assets/images/avatar_default.png">
                      </div>
                    </router-link>
                    <div class="user__data">
                      <h5>
                        <router-link :to="{name: 'Profile', params: {id: post.author.id}}" >
                          {{post.author.fullName}}
                        </router-link>
                      </h5>
                      <p class="post__time caption text-alt" :title="$moment.utc(post.createdAt).local().format('L')">
                        <router-link :to="{name: 'Post', params: {id: post.id}}" >
                          {{$moment.utc(post.createdAt).fromNow()}}
                        </router-link>
                      </p>
                    </div>
                  </div>
                <div class="post__options">
                  <DropdownMenu>
                    <template #button>
                      <button class="btn btn--icon btn--circle btn--xs">
                        <img class="icon" src="@/assets/images/icons/ico_dots--bl.svg">
                      </button>
                    </template>
                    <template #options>
                      <a class="dropdown__option" href="#" @click.prevent="displayShareDialog(post)">
                        Compartir
                      </a>
                      <a v-if="!post.locked && (post.author.id == $store.state.user.id || $store.state.user.role_id == 1)" class="dropdown__option" href="#" @click.prevent="lockQna(post)">
                          No permitir más comentarios en esta publicación
                      </a>
                      <a v-else-if="post.locked && (post.author.id == $store.state.user.id || $store.state.user.role_id == 1)" class="dropdown__option" href="#" @click.prevent="lockQna(post)">
                          Volver a permitir comentarios en esta publicación
                      </a>
                    </template>
                  </DropdownMenu>
                </div>
                </div>
                <div class="post__main-content">
                  <div class="post__titles">
                    <div class="post__type">
                      <div v-if="post.entryType === 1" class="tag">
                        <img class="icon" src="@/assets/images/icons/ico_question--white.svg">
                      </div>
                      <div v-else class="tag">
                        <img class="icon" src="@/assets/images/icons/ico_article--white.svg">
                      </div>
                    </div>
                    <div class="post__title">
                      <router-link :to="{name: 'Post', params: {id: post.id}}" >
                        <h4>{{post.title}}</h4>
                      </router-link>
                    </div>
                  </div>
                  <div v-if="post.tags" class="post__tags">
                    <span class="tag tag--primary post__tag" v-for="tag in post.tags" :key="tag.id">
                      {{tag.name}}
                    </span>
                  </div>
                </div>
                <div class="post__cover-image" v-if="post.fileUrl">
                  <img :src="post.fileUrl" />
                </div>
                <div class="post__footer">
                  <div class="post__interactions-side">
                    <div class="post__interaction">
                      <button type="button" :disabled="post.savingVote" class="btn btn--primary btn--text btn--sm" @click.prevent="vote(post)" v-if="$store.state.user.id">
                        <template v-if="post.voted">
                          <img class="icon" src="@/assets/images/icons/ico_heart_filled--orange.svg"/> {{post.upvoteCount}}
                        </template>
                        <template v-else>
                          <img class="icon" src="@/assets/images/icons/ico_heart--orange.svg"/> {{post.upvoteCount}}
                        </template>
                      </button>
                      <button v-else type="button" class="btn btn--primary btn--text btn--sm" @click.prevent="notSignedIn()">
                        <img class="icon" src="@/assets/images/icons/ico_heart_filled--orange.svg"/> {{post.upvoteCount}}
                      </button>
                    </div>
                    <div class="post__interaction">
                      <button class="btn btn--primary btn--text btn--sm">
                        <router-link :to="{name: 'Post', params: {id: post.id}}" >
                          <img class="icon" src="@/assets/images/icons/ico_comment--orange.svg"> {{post.commentCount}} {{post.entryType === 1 ? 'Respuestas' : 'Comentarios'}}
                        </router-link>
                      </button>
                    </div>
                  </div>
                  <div class="post__interactions-side">
                    <div class="post__interaction">
                      <button class="btn btn--icon btn--circle">
                        <img class="icon" src="@/assets/images/icons/ico_share--orange.svg" @click.prevent="displayShareDialog(post)">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <Loader v-for="index in perPage" :key="index" viewBox="100 100 300 200">
              <rect x="0" y="0" rx="3" ry="3" width="250" height="30" />
              <rect x="20" y="40" rx="3" ry="3" width="260" height="15" />
              <rect x="20" y="70" rx="3" ry="3" width="260" height="15" />
              <rect x="20" y="100" rx="3" ry="3" width="170" height="15" />
            </Loader>
          </div>
        </div>
              <!-- <div class="post-list__end" v-if="this.posts.length == this.total">
                <img src="@/assets/images/illustrations/ill_checklist.png" alt="En la imagen un listado de tareas completado">
              </div> -->
            <!--Fin Publicaciones-->
      </section>
    </div>
  </div>
  <share-dialog v-if="showShareDialog" :sharing="sharing" @close="showShareDialog = false"></share-dialog>
  <register-dialog v-if="showRegisterDialog" @close="showRegisterDialog = false"></register-dialog>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import { ContentLoader as Loader } from 'vue-content-loader'
import ShareDialog from '@/components/ShareDialog.vue'
import DropdownMenu from '@/components/DropdownMenu.vue'
import RegisterDialog from '@/components/RegisterDialog.vue'

export default {
  components: { 
    SideNav, 
    Loader, 
    ShareDialog, 
    DropdownMenu ,
    RegisterDialog
  },
  name: 'MyProfileComponents',
  data() {
    return {
      profile: [],
      posts: [],
      loading: true,
      perPage: 15,
      perPageCopy: 15,
      page: 1,
      loadingMorePosts: false,
      total: null,
      totalComments: null,
      showShareDialog: false,
      sharing: {},
      sortOrder: 'default',
      userId: this.$route.params.id || this.$store.state.user.id,
      routeLoaded: false,
      showRegisterDialog: false
    }
  },  
  async mounted() {
    await this.initialSetup()
  },
  beforeUnmount() {
    this.$nextTick(() => {
      window.removeEventListener('scroll', this.onScroll);
    })
  },
  methods: {
    async initialSetup(){ 
      this.page = 1
      this.total = (await this.$axios.get(`Profile/total/${this.userId}`)).data
      this.totalComments = (await this.$axios.get(`Profile/totalcomments/${this.userId}`)).data
      const res = await this.loadPosts()
      this.loading = false
      this.perPage = 0
      this.page = 2
      this.posts = res

      
      this.profile = (await this.$axios.get(`Users/${this.userId}`)).data
      document.title = `Perfil de ${this.profile.nomUsu} ${this.profile.apePatUsu} ${this.profile.apeMatUsu} - Dorapp`
    },
    async sortEntries() {
      this.perPage = 15
      this.posts.length = 0
      this.loading = true
      this.page = 1

      this.total = (await this.$axios.get(`Profile/total/${this.userId}`)).data
      //this.total = (await this.$axios.get('Qna/total')).data

      const res = await this.loadPosts()
      this.loading = false
      this.perPage = 0
      this.page = 2
      this.posts = res
    },
    async loadPosts() {
      return (await this.$axios.get(`Profile/${this.userId}/${this.page}/${this.sortOrder}`)).data
    },
    async onScroll() {
      if(this.$refs.container.getBoundingClientRect().bottom < window.innerHeight + 300 && !this.loadingMorePosts && this.posts.length < this.total){
        this.loadingMorePosts = true
        this.perPage = this.perPageCopy
        const res = await this.loadPosts()
        this.page = this.page + 1
        this.perPage = 0
        this.posts.push(...res)
        this.loadingMorePosts = false
      }
    },
    async vote(post) {
      post.savingVote = true
      const res = (await this.$axios.post('Qna/vote', {post_id: post.id}))
      if(res.status == 200){
        post.voted = res.data.voted
        post.upvoteCount = res.data.upvoteCount
      }
      post.savingVote = false
    },
    displayShareDialog(post){
      this.showShareDialog = true
      this.sharing.url = `${window.location.origin}/publicacion/${post.id}`
      this.sharing.title = post.title || post.description
      //this.sharing.description = post.description
      //this.sharing.quote = 'quote'
      this.sharing.hashtags = post.tags.map(n => n.name).join(',')
      //this.sharing.twitterUser = 'twitterUser'
    },
    lockQna(post){
      if(confirm(`¿Está seguro de que desea ${post.locked ? 'desbloquear' : 'bloquear'} la ${post.entryType === 1 ? 'Pregunta' : 'Publicación'}?`)){
        this.$axios.post('Qna/lock', {qna_id: post.id}).then(res => {
          if(res.status == 200){
            post.locked = !post.locked
            this.$toast.success(`${post.entryType === 1 ? 'La pregunta' : 'La publicación'} ${!post.locked ? 'ha sido desbloqueada exitosamente' : 'ha sido bloqueada exitosamente'}`)
          }
        })
      }
    },
    closeRegisterModal() {
      this.showRegisterDialog = false
    },
    notSignedIn() {
      this.showRegisterDialog = true
    }
  },
  watch:{
    "$route": async function(route) {
      if(['Profile', 'MyProfile'].includes(route.name)){
        this.userId = route.params.id || this.$store.state.user.id,
        await this.initialSetup()
      }
    }
  }
}
</script>

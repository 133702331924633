<template>
  <div class="modal-backdrop">
    <div class="modal modal--large">
      <div class="modal-container">
        <header>
          <h3>Compartir</h3>
          <p class="modal__description">Copia el siguiente enlace y/o compártelo en redes sociales</p>
        </header>
        <section>
          <div class="form__group">
            <input class="form__input" type="text" :value="sharing.url" readonly>
          </div>
          <div class="social-buttons">
            <ShareNetwork
              v-for="network in networks"
              :network="network.network"
              :key="network.network"
              :style="{backgroundColor: network.color}"
              :url="sharing.url"
              :title="sharing.title"
              :description="sharing.description"
              :quote="sharing.quote"
              :hashtags="sharing.hashtags"
              :twitterUser="twitter"
              class="btn btn--icon btn--circle"
            >
              <img class="icon icon--social" :src="require(`@/assets/images/icons/${network.image}.svg`)" />
            </ShareNetwork>
          </div>
        </section>
        <section class="form__actions">
            <button class="btn btn--primary" @click.prevent="$emit('close')">Cerrar</button>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalDialog',
  props: {
    sharing: Object
  },
  data() {
    return {
      networks: [
        { network: 'facebook', name: 'Facebook', image: 'ico_social_facebook--white', color: '#1877f2' },
        { network: 'linkedin', name: 'LinkedIn', image: 'ico_social_linkedin--white', color: '#007bb5' },
        { network: 'twitter', name: 'Twitter', image: 'ico_social_twitter--white', color: '#1da1f2' },
        { network: 'whatsapp', name: 'Whatsapp', image: 'ico_social_whatsapp--white', color: '#25d366' }
      ],
      twitter: ''
    }
  },
  mounted() {
    if(this.sharing.twitterUser != null )
      this.twitter = this.sharing.twitterUser.replace('@', '')
  }
}
</script>
